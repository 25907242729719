import request from '@/http/request';
import { useQuery } from '@tanstack/react-query';
import { EventId, Banana } from './game';
export enum GameItemId {
  cells = 1,
  undo = 2,
  hint = 3,
  shuffle = 4,
  revive = 5,
  spin = 6,
  ticket = 7,
  skip = 9,
}

export interface UserModel {
  tgId: string;
  name: string;
  headerUrl?: string;
  registerIp: string;
  registerCountry: string;
  registerCity: string;
  registerTime: string;
  gold: string;
  bananaCount: string;
  depositAmount: string;
  bananaOnlineId: string;
  claimEasterEgg: boolean;
  skipToday: boolean;
  skipNum: number;
}

export interface GameItemDTOList {
  gameItemId: GameItemId;
  gameItemName: string;
  gameItemType: string;
  gameItemPrice: string;
  haveCount: string;
}

export interface UserGameStatusDTO {
  playTimes: string;
  spinTimes: string;
  successTimes: string;
  playFreeTimes: string;
  journey: {
    index: number;
    difficultyCoefficient: number;
    nextPrice: string;
    nextRewards: string;
    currentLevel: number;
    isLastLevel: false;
  };
  lastSpinTime: string;
  reviveEarnMethod: EventId[];
  ticketEarnMethod: EventId[];
}

export const fetchLogin = (data: {
  inviteCode?: number | string;
  utmData?: any;
  easterEggCode?: string;
  cloudflareTurnstileToken?: string;
}) => {
  return request<{
    userModel: UserModel;
    userGameStatusDTO: UserGameStatusDTO;
    gameItemDTOList: GameItemDTOList[];
    claimEasterEgg: boolean;
  }>({
    url: '/api/mini-api/user/login',
    data,
    method: 'post',
    suppressWarning: false,
  });
};

export const fetchUserStatus = () => {
  return request<{
    userModel: UserModel;
    userGameStatusDTO: UserGameStatusDTO;
    gameItemDTOList: GameItemDTOList[];
    claimEasterEgg: boolean;
  }>({
    url: '/api/mini-api/user/get-new-status',
    data: {},
    method: 'post',
    suppressWarning: false,
  });
};

export interface RecordItem {
  id: string;
  tgId: string;
  eventTypeId: string;
  gameItemId: string;
  amountGold: string;
  payoutGold: string;
  banana: Banana;
  consumableGameItemId: string;
  finishTime: string;
  createdTime: string;
  updatedTime: string;
  amount: string;
  coin: string;
  itemCount: string;
}

export interface FetchUserRecordProps {
  typeId?: string;
  prevId?: number;
  nextId?: number;
  pageSize?: number;
}
export const fetchUserRecord = (data: FetchUserRecordProps) => {
  return request<{
    list: RecordItem[];
  }>({
    url: '/api/mini-api/user/record',
    data: data,
    method: 'post',
    suppressWarning: false,
  });
};
export const useUserRecord = (data: FetchUserRecordProps) => {
  return useQuery({
    queryKey: ['userRecord'],
    queryFn: () => fetchUserRecord(data),
  });
};

export const userSkip = () => {
  return request<{
    skipToday: boolean;
    skipNum: number;
  }>({
    url: '/api/mini-api/user/update-skip',
    data: {},
    method: 'post',
    suppressWarning: false,
  });
};
