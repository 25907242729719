'use client';
import Image from 'next/image';
import * as Sentry from '@sentry/nextjs';
import { useSearchParams } from 'next/navigation';
import { clsx } from 'clsx';
import { useTranslations } from 'next-intl';
import LocalizedLink from '@/components/ui/LocalizedLink';
import { useLocale } from 'next-intl';
import { usePathname } from '@/helper/navigation';
import { Button } from '@/components/ui/button';
import { fetchLogin } from '@/apis/login';
import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import {
  getInviteCode,
  getEasterEggCode,
  customDecode,
} from '@/helper/getCode';
import {
  userInfoAtom,
  gameItemDTOListAtom,
  userGameStatus,
  userEasterEggInfoAtom,
  cloudflareTurnstileTokenAtom,
} from '@/store/userInfo';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { enableMusicAtom } from '@/store/settings';
import { store } from '@/store';
import useAppStatus from '@/hooks/useAppStatus';
import { trackEvent, setUserProperties } from '@/helper/analytics';
import { initBGM, AudioElement, handleCanPlayThrough } from '@/utils/initBGM';
import { fetchEasterEggInfo } from '@/apis/easterEgg';
import thinkingdata from 'thinkingdata-browser';
import { track } from '@/utils/thinkdata/track';

export const navItems = {
  earn: {
    menuIndex: 1,
    link: '/earn',
    text: 'Earn',
  },
  friends: {
    menuIndex: 2,
    link: '/friends',
    text: 'Friends',
  },

  lobby: {
    menuIndex: 3,
    link: '/',
    text: 'Lobby',
  },

  store: {
    menuIndex: 4,
    link: '/store',
    text: 'Store',
  },
  bag: {
    menuIndex: 5,
    link: '/task',
    text: 'Task',
  },
};

function getUtmData(searchParams): any {
  const tgWebAppStartParam =
    searchParams.get('tgWebAppStartParam') || searchParams.get('params');
  if (!tgWebAppStartParam) return;
  if (tgWebAppStartParam.includes('utm_id')) {
    const utmId = customDecode(tgWebAppStartParam)?.['utm_id'];
    return {
      utmId,
    };
  }
  return;
}

export default function Navigator({ className }: { className?: string }) {
  const { visibilityState, isFocused } = useAppStatus();
  const t = useTranslations();
  const locale = useLocale();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [gameItemDTOList, setGameItemDTOList] = useAtom(gameItemDTOListAtom);
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);
  const [userGame, setUserGameStatus] = useAtom(userGameStatus);
  const [easterEggInfo, setUserEasterInfo] = useAtom(userEasterEggInfoAtom);
  const [cloudflareTurnstileToken] = useAtom(cloudflareTurnstileTokenAtom);
  const [enableMusicEffects] = useAtom(enableMusicAtom);

  const { mutate: login } = useMutation({
    retry: 3,
    mutationFn: () => {
      const inviteCode = getInviteCode(searchParams);
      const utmData = getUtmData(searchParams);
      const easterEggCode = getEasterEggCode(searchParams);
      return fetchLogin({
        inviteCode,
        utmData,
        easterEggCode,
        cloudflareTurnstileToken: cloudflareTurnstileToken,
      });
    },
    onSuccess: ({ data }) => {
      setGameItemDTOList(data.gameItemDTOList);
      setUserInfo({ ...data.userModel, claimEasterEgg: data.claimEasterEgg });
      setUserGameStatus(data.userGameStatusDTO);

      trackEvent('login');
      setUserProperties({
        tgId: data.userModel.tgId,
        name: data.userModel.name,
        gold: data.userModel.gold,
        registerCountry: data.userModel.registerCountry,
        bananaCount: data.userModel.bananaCount,
        bananaOnlineId: data.userModel.bananaOnlineId,
        claimEasterEgg: data.claimEasterEgg,
      });

      // thinkdata
      const deviceId = thinkingdata.getDeviceId();
      thinkingdata.login(userInfo.tgId);
      // Global Public Properties
      thinkingdata.setSuperProperties({
        userId: data.userModel.tgId,
        registerCountry: data.userModel.registerCountry,
      });

      // Login event track
      track('Login', {
        tgId: data.userModel.tgId,
        name: data.userModel.name,
        gold: data.userModel.gold,
        registerCountry: data.userModel.registerCountry,
        bananaCount: data.userModel.bananaCount,
        bananaOnlineId: data.userModel.bananaOnlineId,
        claimEasterEgg: data.claimEasterEgg,
        time: Date.now(),
      });
    },
    onError: (error) => {
      Sentry.captureMessage('Login Error', {
        extra: {
          error: JSON.stringify(error),
        },
      });
      console.error('Login failed:', error);
    },
  });

  const { mutate: getEasterEggInfo } = useMutation({
    mutationFn: () => {
      return fetchEasterEggInfo();
    },
    onSuccess: ({ data }) => {
      if (data?.easterEggDataDTO) {
        setUserEasterInfo(data.easterEggDataDTO);
      } else {
        setUserEasterInfo(null);
      }
    },
    onError: (error) => {
      Sentry.captureMessage('EasterEggInfo Error', {
        extra: {
          error: JSON.stringify(error),
        },
      });
      console.error('EasterEggInfo failed:', error);
    },
  });

  useEffect(() => {
    initBGM();
  }, []);

  useEffect(() => {
    if (!userInfo?.tgId || !!easterEggInfo) return;
    getEasterEggInfo();
  }, [userInfo]);

  useEffect(() => {
    if (cloudflareTurnstileToken) {
      login();
    }
  }, [cloudflareTurnstileToken]);

  useEffect(() => {
    if (visibilityState === 'visible') {
      handleCanPlayThrough(enableMusicEffects);
    } else if (visibilityState === 'hidden') {
      AudioElement.pause();
    }
  }, [visibilityState, enableMusicEffects]);

  const menuList = [
    {
      ...navItems.earn,
      bgColor: '#446eaa',
      text: t('earn'),
      icon: 'earn1',
    },
    {
      ...navItems.friends,
      bgColor: '#65538a',
      text: t('friends'),
      icon: 'friends',
    },

    {
      ...navItems.lobby,
      bgColor: '#c4d73d',
      text: t('lobby'),
      icon: 'lobby',
    },

    {
      ...navItems.store,
      bgColor: '#ae803e',
      text: t('store'),
      icon: 'store',
    },
    {
      ...navItems.bag,
      bgColor: '#efc553',
      text: t('task'),
      icon: 'task_2',
    },
  ];
  const hiddenNavigator = ![
    '/game',
    '/spin',
    '/bag',
    '/playOnMobile',
    '/shareReward',
  ].includes(pathname);
  return (
    <>
      {hiddenNavigator ? (
        <div className={`${className} font-comicbd min-w-[20rem] z-50`}>
          <Button
            disableHoverAndActive={true}
            shadowColor="#ada898"
            className="!px-2 !flex items-center bg-[#f2f8e2]"
          >
            {menuList.map((item, index) => (
              <LocalizedLink
                style={{
                  backgroundColor:
                    item.link === pathname ? item.bgColor : 'transparent',
                }}
                className={clsx(
                  `relative inline-block transition-all duration-500 `,
                  {
                    'px-[2%]': item.link === pathname,
                  },
                )}
                href={item.link}
                key={index}
                prefetch={true}
              >
                <Image
                  width={93}
                  height={93}
                  className={clsx(
                    '  mx-auto inline-block -translate-y-1/4 transition-all',
                    {
                      '!-translate-y-1/3': item.link === pathname,
                    },
                  )}
                  src={getPublicAsset(`/images/lobby/${item.icon}.png`)}
                  alt=""
                />
                <div
                  className={clsx(
                    'text-black/50 absolute block left-1/2 -translate-x-1/2 bottom-[3%] text-xs',
                    {
                      '!text-black !text-sm font-bold delay-300 duration-100 whitespace-nowrap':
                        item.link === pathname,
                    },
                  )}
                >
                  {item.text}
                </div>
              </LocalizedLink>
            ))}
          </Button>
        </div>
      ) : null}
    </>
  );
}
